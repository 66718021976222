@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@600&family=Mulish&display=swap");
@import "colors";

$body-color: $white;
$btn-border-radius: 0;
$input-border-radius: 0;
$input-border-color: $lila;

@import "~bootstrap/scss/bootstrap";

@import "overrides";
@import "variables";
@import "ui";

body {
  background: black;
  overflow-y: scroll;
}

.Container {
  &--xs {
    max-width: 860px;
  }
  &--sm {
    max-width: 1000px;
  }
  &--centered {
    @extend .min-vh-100;
  }

  &__Dashboard {
    padding-left: $navigation-width;
  }
}

.Divider {
  position: relative;
  padding: 1.5rem 0;

  &:after {
    content: "";
    width: 75%;
    height: 1px;
    background: $grey;
    position: absolute;
    top: 50%;
    left: 12.5%;
    z-index: 0s;
  }

  &__Span {
    position: relative;
    background: $black;
    padding: 0 1rem;
    z-index: 1;
  }
}

// Nav
.Logo {
  max-width: 100%;
}
.Nav {
  width: $navigation-width;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(121, 80, 182, 0.08);

  .Logo {
    width: 180px;
    padding: 2rem 0 3rem;
  }

  &__List {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    &__Divider {
      width: 100%;
      height: 1px;
      opacity: 0.125;
      background: #dfe0eb;
    }

    &__Item {
      display: flex;
      transition: all 0.125s ease-in;
      a {
        width: 100%;
        text-decoration: none;
        color: $metall;
        font-family: "Mulish", sans-serif;

        padding: 1rem 1.25rem;
        box-sizing: border-box;
      }

      .active,
      &:hover {
        position: relative;
        color: $lila;
        background: rgba(121, 80, 182, 0.08);

        &:after {
          content: "";
          width: 3px;
          height: 100%;
          background: $lila;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &__Icon {
        margin-right: 1rem;
      }
    }
  }
}

// Fonts
body {
  font-family: "Archivo", sans-serif;
}
.Headline {
  font-family: $font-archivo-black;

  &--glowing {
    color: $lila;
    background: -webkit-linear-gradient(170deg, $lila, $purple);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &--light {
    font-family: $font-archivo;
    font-weight: $font-weight-light;
  }

  &--rotate {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    margin-left: 0;
    text-align: center;
  }
}
