input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $lila;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.Input {
  background: rgba(121, 80, 182, 0.1);
  border: 1px solid $lila;
  padding: 0.375rem;
  outline: none;
  color: #fff;
  font-weight: $font-weight-light;

  &:focus {
    background: none;
    border-color: $lila;
    box-shadow: none;
  }

  &--blue {
    background-color: rgba(87, 201, 241, 0.1);
    border-color: $blue;
  }
}

.Button {
  &--primary {
    border: none;
    border-radius: 0;
    background: linear-gradient(135deg, $purple 0%, $lila 100%);
    color: $white;
  }

  &__MetaMask {
    border: 1px solid $orange;
    background: rgba(246, 133, 27, 0.1);

    &:hover {
      border: 1px solid $orange;
      background: rgba(246, 133, 27, 0.5);
    }

    &--logo {
      width: 3rem;
      margin-right: 1rem;
    }
  }
}

.ProgressBar {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1rem;
  background: #523f62;

  &__Indicator {
    width: 0%;
    height: 100%;

    background: linear-gradient(270deg, $purple 0%, $lila 100%);

    transition: width 0.5s ease-in;
  }
}

.Graph {
  &__Container {
    padding: 1rem 2rem;
    background: linear-gradient(135deg, #0e0328, #2a042f);
  }
}
