.btn-outline-secondary {
  border-color: $blue;
  background: rgba(87, 201, 241, 0.1);
  color: $white;

  &:hover {
    border-color: $lila;
    background: rgba(225, 12, 124, 0.1);
  }
}

.form-control {
  background: rgba(121, 80, 182, 0.1);
  &:focus {
    background: none;
    border-color: $lila;
    box-shadow: none;
  }
}
